import ComingSoon from "./Components/ComingSoon/ComingSoon";

function App() {
  return (
    <div className="App">
      <ComingSoon></ComingSoon>
    </div>
  );
}

export default App;
